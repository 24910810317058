<template>
  <v-container class="d-flex flex-column" fluid style="min-height: 100vh; padding: 0px">
    <v-row style="background-color: white; padding: 0px; max-height: 220px">
      <v-col class="d-flex flex-column position-relative align-center">
        <div
          style="
            position: absolute;
            right: 20px;
            top: 20px;          
          "
        >
          <v-btn
            append-icon="mdi-content-copy"
            style="
              font-weight: bold;
              margin-right: 5px;
            "
            variant="text"
            size="small"
            @click="copyText($event, linksText())"
            >Copiar enlaces</v-btn
          >
          <v-tooltip location="bottom" text="Instala la jerarquía de certificados de CA de ANF en su ordenador">
            <template v-slot:activator="{ props }">
              <v-btn
              style="
                font-weight: bold;
              "
                v-bind="props"
                append-icon="mdi-download"
                color="success"
                variant="tonal"
                elevation="2"
                rounded="lg"
                size="small"
                @click="downloadCAInstaller"
              >
                Instalador automático
              </v-btn>
            </template>
          </v-tooltip>
        </div>
        <div
          style="
            position: absolute;
            right: 10px;
            top: 50px;
            display: flex;
            justify-content: flex-end;
          "
        >
        
        </div>

        <a
          class="text-decoration-none"
          href="https://www.anf.es"
          style="background-color: white; max-height: 150px;"
        >
          <img :src="require('@/assets/anfac-text.png')" alt="ANF AC" height="100%" />
        </a>
        <div
          class="d-flex align-items-end justify-content-end"
          style="
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: white;
            margin-right: 10%;
            text-align: right;
            font-weight: bold;
            gap: 10px;
          "
        >
          <span class="mb-2">Mostrar:</span>
          <v-checkbox
            class="d-flex align-items-end justify-content-end text-wrap"
            v-model="showTsu"
            style="
              font-size: 14px;
              background-color: white;
              color: black;
              font-weight: bold;
            "
            @click="setShowTsu()"
            label="TSU"
            density="compact"
            color="black"
          ></v-checkbox
          ><v-checkbox
            class="d-flex align-items-end justify-content-end text-wrap"
            v-model="showSeal"
            style="
              font-size: 14px;
              background-color: white;
              color: black;
              font-weight: bold;
            "
            @click="setShowSeal()"
            density="compact"
            color="black"
            label="QTS Dig. Id."
          ></v-checkbox>
        </div>
      </v-col>
    </v-row>
    <v-row class="section">
      <v-col>
        <h3 style="text-align: center">ANF AC Hierarchies and CRLs</h3>
        <div id="12212" style="margin-left: 10%; margin-right: 10%" v-if="loaded">
          <div v-for="crl in model.crls" :key="crl.id">
            <div :style="getCrlStyle(crl)">
              <div
                v-if="crl.ca.issuer"
                style="
                  height: 100px;
                  width: 50px;
                  vertical-align: middle;
                  margin: 10px;
                  margin-top: 20px;
                "
              ></div>

              <div
                style="
                  margin-left: 20px;
                  margin-bottom: 20px;
                  margin-top: 20px;
                  text-align: left;
                "
              >
                <a
                  :style="{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: 'black',
                    textDecoration: 'none',
                  }"
                  :href="
                    '#' + getFormattedLink(crl.history.issuer, crl.ca.expirationDate)
                  "
                  :id="'' + getFormattedLink(crl.history.issuer, crl.ca.expirationDate)"
                >
                  {{ crl.history.issuer }}
                </a>
                <div style="display: flex">
                  <div>
                    <p>
                      Validez CA:
                      <span style="font-weight: bold">
                        {{ formatDate(crl.ca.issueDate, "DD/MM/yyyy") }} -
                        {{ formatDate(crl.ca.expirationDate, "DD/MM/yyyy") }}
                      </span>
                      <br />
                      <a
                        class="text-decoration-none"
                        style="font-weight: bold"
                        :href="'/certificates-download/' + crl.ca.name"
                        >Descargar CA</a
                      >
                    </p>
                    <div></div>
                  </div>

                  <div style="margin-left: 30px">
                    <div>
                      <p>
                        {{ getRevokedCertsText(crl.ca.issuer) }}
                        <span style="font-weight: bold">{{
                          crl.history.revokedCertCount
                        }}</span>
                        <br />
                        <a
                          class="text-decoration-none"
                          style="font-weight: bold"
                          :href="'/crl/' + crl.history.nameCRL"
                          >Descargar {{ getCRLText(crl.ca.issuer) }}</a
                        >
                        <span v-if="showOcspLink">
                          /
                          <a
                            class="text-decoration-none"
                            style="font-weight: bold"
                            :href="model.ocspUrl + '/responder?ca=' + getSerial(crl.ca)"
                            >OCSP resp.</a
                          >
                        </span>
                      </p>
                    </div>
                  </div>

                  <div style="margin-left: 30px">
                    <p>
                      Fecha efectiva {{ crl.ca.issuer != null ? "CRL" : "ARL" }}:
                      <span style="font-weight: bold">{{
                        formatDate(crl.history.thisUpdate, "DD/MM/yyyy")
                      }}</span>
                      <br />
                      Next Update {{ crl.ca.issuer != null ? "CRL" : "ARL" }}:
                      <span style="font-weight: bold">{{
                        formatDate(crl.history.nextUpdate, "DD/MM/yyyy")
                      }}</span>
                    </p>
                  </div>

                  <div style="margin-left: 30px">
                    <div style="display: flex">
                      <span style="display: inline-block">Serial: </span>
                      <span style="font-weight: bold">
                        <div style="display: flex">
                          <div>
                            <span style="margin-right: 20px">{{ crl.ca.serial }}</span>
                          </div>
                          <div>
                            <a
                              :id="'dec-' + crl.ca.serial"
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              style="pointer-events: none; color: black"
                              @click="
                                hexdec($event, crl.ca, 'hex-' + crl.ca.serial, 16, 10)
                              "
                              >Dec</a
                            >
                            <span style="margin-left: 5px; margin-right: 5px"> / </span>
                            <a
                              :id="'hex-' + crl.ca.serial"
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              @click="
                                hexdec($event, crl.ca, 'dec-' + crl.ca.serial, 10, 16)
                              "
                              >Hex</a
                            >
                          </div>
                        </div>
                      </span>
                    </div>

                    <div>
                      Fingerprint:
                      <span :title="crl.ca.sha256Fingerprint">
                        <a
                          style="font-weight: bold"
                          class="text-decoration-none"
                          href="javascript:void(0)"
                          @click="copyText($event, crl.ca.sha256Fingerprint)"
                          >Copiar</a
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="
                showSeal &&
                model.caSeals?.[crl.ca.serial] &&
                model.caSeals?.[crl.ca.serial].length != 0
              "
            >
              <div v-for="seal in model.caSeals?.[crl.ca.serial]" :key="seal.id">
                <div
                  style="
                    margin-bottom: 30px;
                    margin-top: 20px;
                    margin-left: 50px;
                    background-color: #daffe6;
                    display: flex;
                    vertical-align: middle;
                    align-content: center;
                  "
                >
                  <div
                    style="
                      height: 100px;
                      width: 50px;
                      vertical-align: middle;
                      margin: 10px;
                      margin-top: 20px;
                    "
                  ></div>

                  <div
                    style="
                      margin-left: 70px;
                      margin-bottom: 20px;
                      margin-top: 20px;
                      text-align: left;
                    "
                  >
                    <a
                      style="
                        font-weight: bold;
                        font-size: 18px;
                        color: black;
                        text-decoration: none;
                      "
                      :id="seal.name.replace(' ', '-')"
                      :href="
                        seal.link
                          ? seal.link
                          : '#' + seal.serial + '-' + seal.name.replace(' ', '-')
                      "
                      :target="seal.link ? '_blank' : ''"
                      >{{ seal.name }}
                      <v-icon v-if="seal.link" size="x-small">mdi-open-in-new</v-icon></a
                    >

                    <div style="display: flex">
                      <div>
                        <p>
                          Validez:
                          <span style="font-weight: bold"
                            >{{ formatDate(seal.issueDate, "DD/MM/yyyy") }} -
                            {{ formatDate(seal.expirationDate, "DD/MM/yyyy") }}</span
                          >
                          <br />
                          <a
                            class="text-decoration-none"
                            style="font-weight: bold"
                            :download="seal.name + ' - ' + seal.serial + '.cer'"
                            :href="seal.download"
                            >Descargar</a
                          >
                        </p>
                        <div></div>
                      </div>

                      <div style="margin-left: 30px">
                        <div style="display: flex">
                          <span style="display: inline-block">Serial: </span>
                          <span style="font-weight: bold">
                            <div style="display: flex">
                              <div>
                                <span style="margin-right: 20px">{{ seal.serial }}</span>
                              </div>
                              <div>
                                <a
                                  :id="'dec-' + seal.serial"
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                  style="pointer-events: none; color: black"
                                  @click="
                                    hexdec($event, seal, 'hex-' + seal.serial, 16, 10)
                                  "
                                  >Dec</a
                                >
                                <span style="margin-left: 5px; margin-right: 5px">
                                  /
                                </span>
                                <a
                                  :id="'hex-' + seal.serial"
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                  @click="
                                    hexdec($event, seal, 'dec-' + seal.serial, 10, 16)
                                  "
                                  >Hex</a
                                >
                              </div>
                            </div>
                          </span>
                        </div>

                        <div>
                          Fingerprint:
                          <span :title="seal.sha256Fingerprint">
                            <a
                              style="font-weight: bold"
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              @click="copyText($event, seal.sha256Fingerprint)"
                              >Copiar</a
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="
                showTsu &&
                model.caTsus?.[crl.ca.serial] &&
                model.caTsus?.[crl.ca.serial].length != 0
              "
            >
              <div v-for="tsu in model.caTsus?.[crl.ca.serial]" :key="tsu.id">
                <div
                  style="
                    margin-bottom: 30px;
                    margin-top: 20px;
                    margin-left: 50px;
                    background-color: #e9e6f6;
                    display: flex;
                    vertical-align: middle;
                    align-content: center;
                  "
                >
                  <div
                    style="
                      height: 100px;
                      width: 50px;
                      vertical-align: middle;
                      margin: 10px;
                      margin-top: 20px;
                    "
                  ></div>

                  <div
                    style="
                      margin-left: 70px;
                      margin-bottom: 20px;
                      margin-top: 20px;
                      text-align: left;
                    "
                  >
                    <a
                      style="
                        font-weight: bold;
                        font-size: 18px;
                        color: black;
                        text-decoration: none;
                      "
                      :id="tsu.name.replace(' ', '-')"
                      :href="'#' + tsu.name.replace(' ', '-')"
                      >{{ tsu.name }}</a
                    >

                    <div style="display: flex">
                      <div>
                        <p>
                          Validez:
                          <span style="font-weight: bold"
                            >{{ formatDate(tsu.issueDate, "DD/MM/yyyy") }} -
                            {{ formatDate(tsu.expireDate, "DD/MM/yyyy") }}</span
                          >
                          <br />
                          <a
                            class="text-decoration-none"
                            style="font-weight: bold"
                            :href="tsu.link"
                            >Descargar TSU</a
                          >
                        </p>
                        <div></div>
                      </div>

                      <div style="margin-left: 30px">
                        <div style="display: flex">
                          <span style="display: inline-block">Serial: </span>
                          <span style="font-weight: bold">
                            <div style="display: flex">
                              <div>
                                <span style="margin-right: 20px">{{
                                  tsu.serialNumber
                                }}</span>
                              </div>
                              <div>
                                <a
                                  :id="'dec-' + tsu.serialNumber"
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                  style="pointer-events: none; color: black"
                                  @click="
                                    hexdec($event, tsu, 'hex-' + tsu.serialNumber, 16, 10)
                                  "
                                  >Dec</a
                                >
                                <span style="margin-left: 5px; margin-right: 5px">
                                  /
                                </span>
                                <a
                                  :id="'hex-' + tsu.serialNumber"
                                  class="text-decoration-none"
                                  href="javascript:void(0)"
                                  @click="
                                    hexdec($event, tsu, 'dec-' + tsu.serialNumber, 10, 16)
                                  "
                                  >Hex</a
                                >
                              </div>
                            </div>
                          </span>
                        </div>

                        <div>
                          Fingerprint:
                          <span :title="tsu.fingerprint">
                            <a
                              style="font-weight: bold"
                              class="text-decoration-none"
                              href="javascript:void(0)"
                              @click="copyText($event, tsu.fingerprint)"
                              >Copiar</a
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex align-center justify-center fill-width w-100 h-100"
          style="max-width: 100%; max-height: 100%"
          height="100%"
        >
          <div
            class="d-flex flex-column align-center justify-center"
            style="height: 400px"
            height="300px"
          >
            <v-progress-circular
              v-if="!loadError"
              indeterminate
              size="50"
            ></v-progress-circular>
            <img
              v-else
              src="/error.svg"
              alt="Error"
              width="80px"
              height="80px"
              style="color: red"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      name="footer"
      :class="{ footer: loaded || loadError, section: !(loaded || loadError) }"
      style="
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vh;
        padding: 0px;
        max-height: 15vh;
      "
    >
      <v-col>
        <p v-if="loaded || loadError">
          Copyright © 2000 - {{ year }} ANF AC. Todos los derechos reservados. Technology
          powered by ANF AC
        </p>
      </v-col>
    </v-row>
    <v-overlay
      :model-value="!loaded && !loadError"
      class="d-flex align-center justify-center fill-width w-100 h-100"
    >
    </v-overlay>
  </v-container>
</template>

<script>
import config from "@/store/config";
import moment from "moment";
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      loaded: false,
      loadError: false,
      showTsu: false,
      showSeal: false,
      showOcspLink: true,
      model: {
        crls: [],
        caTsus: {},
        caSeals: {},
      },
      test: true,
      year: "2024",
    };
  },
  mounted() {
    console.log(sessionStorage);
    this.showTsu = sessionStorage.getItem("showTsu") === "true";
    this.showSeal = sessionStorage.getItem("showSeal") === "true";
    console.log("tsu:" + this.showTsu + " seal:" + this.showSeal);
    this.list();
  },
  methods: {
    list() {
      axios
        .get(config.baseUrl + "/crl-service/list-crls")
        .then((response) => {
          console.log(response.data);
          this.model.crls = response.data.crls;
          this.model.ocspUrl = response.data.ocspUrl;
          this.year = response.data.year;
          this.loaded = true;
          if (this.showTsu) this.listTSUs();
          if (this.showSeal) this.listSeals();
          this.$forceUpdate();
          if (window.location.hash) {
            // Get the element corresponding to the hash fragment
            const targetElement = document.getElementById(
              window.location.hash.substring(1)
            );
            console.log(window.location.hash.substring(1) + ": " + targetElement);
            // Scroll to the target element if it exists
            if (targetElement) {
              targetElement.scrollIntoView({ behavior: "smooth" });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadError = true;
        });
    },
    listTSUs() {
      console.log("listTSUs " + JSON.stringify(this.model.crls));
      this.model.crls.forEach((crl) => {
        console.log(crl.ca.serial + ":" + crl.ca.tsu);
        if (crl.ca.tsu) this.listCrlTSUs(crl.ca.serial);
      });
    },
    listCrlTSUs(serial) {
      console.log("list tsu of ca " + serial);
      axios
        .get(config.baseUrl + "/crl-service/list-crl-tsu?serial=" + serial)
        .then((response) => {
          console.log(response.data);
          this.model.caTsus[serial] = response.data;
          this.$forceUpdate();
          // console.log("caTsus: " + JSON.stringify(this.model.caTsus));
        })
        .catch((error) => {
          console.log(error);
          this.loadError = true;
        });
    },
    listSeals() {
      console.log("listSeals " + JSON.stringify(this.model.crls));
      this.model.crls.forEach((crl) => {
        console.log(crl.ca.serial + ": seal:" + crl.ca.seal);
        if (crl.ca.seal) this.listCaSeals(crl.ca.serial);
      });
    },
    listCaSeals(serial) {
      console.log("list seals of ca " + serial);
      axios
        .get(config.baseUrl + "/crl-service/list-crl-seal?serial=" + serial)
        .then((response) => {
          console.log(response.data);
          response.data.forEach((certificate) => {
            certificate.download =
              "data:application/x-x509-ca-cert;base64," + certificate.cert;
          });

          this.model.caSeals[serial] = response.data;
          this.$forceUpdate();
          // console.log("caTsus: " + JSON.stringify(this.model.caTsus));
        })
        .catch((error) => {
          console.log(error);
          this.loadError = true;
        });
    },
    setShowSeal() {
      sessionStorage.setItem("showSeal", !this.showSeal);
      console.log(sessionStorage);
      this.listSeals();
    },
    setShowTsu() {
      sessionStorage.setItem("showTsu", !this.showTsu);
      console.log(sessionStorage);
      this.listTSUs();
    },
    getCrlStyle(crl) {
      const defaultStyle = {
        marginBottom: "30px",
        marginTop: "20px",
        marginLeft: "50px",
        display: "flex",
        verticalAlign: "middle",
        alignContent: "left",
      };
      if (crl.ca.issuer) {
        return {
          ...defaultStyle,
          backgroundColor: "#F2F6FF",
        };
      } else {
        return {
          ...defaultStyle,
          backgroundColor: "#E6EDFF",
        };
      }
    },
    getFormattedLink(issuer, expirationDate) {
      return `${issuer.replace(/ /g, "-")}-${this.formatDate(expirationDate, "yyyy")}`;
    },
    formatDate(date, format) {
      return moment(String(date), "YYYY-MM-DD HH:mm:ss.SSS Z").locale("L").format(format);
    },
    getRevokedCertsText(issuer) {
      return !issuer ? "CAs intermedias revocadas:" : "Certificados revocados:";
    },
    getCRLText(issuer) {
      return !issuer ? "ARL" : "CRL";
    },
    copyText(event, textToCopy) {
      const elem = event.target;
      const text = elem.textContent;
      // Por ejemplo, aquí hay una forma simple de copiar el texto al portapapeles:
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          elem.textContent = "Copiado!";
          setTimeout(() => {
            elem.textContent = text;
          }, 500);
        })
        .catch((err) => {
          console.error("Error al copiar el texto:", err);
        });
    },
    linksText() {
      const baseURL = window.location.origin;
      let text = "";
      this.model.crls.forEach((crl) => {
        if (!crl.ca.issuer) {
          if (text.length > 0) text += "\n";
          text += "Raiz: ";
        } else text += "  IA: ";
        text += crl.history.issuer;
        text += "\n  ";
        if (crl.ca.issuer) text += "  ";
        text += baseURL + "/certificates-download/" + crl.ca.name;
        text += "\n";
      });
      return text;
    },
    downloadCAInstaller() {
      // Construct the URL to the executable file
      const fileUrl = `/InstaladorJerarquias.exe`;

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "InstaladorJerarquias.exe";

      // Append the anchor element to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the anchor element after download
      document.body.removeChild(link);
    },
    // getTsus(serial) {
    //   return this.model.caTsus ? this.model.caTsus[serial] : [];
    // },
    getSerial(ca) {
      if (ca.base && ca.base == 16) {
        return this.convertBase(ca.serial, 16, 10);
      }
      return ca.serial;
    },
    hexdec(event, obj, enableElementId, fromBase, toBase) {
      const elem = event.target;
      if (obj.serial)
        obj.serial = this.convertBase(obj.serial, fromBase, toBase).toUpperCase();
      else if (obj.serialNumber)
        obj.serialNumber = this.convertBase(
          obj.serialNumber,
          fromBase,
          toBase
        ).toUpperCase();
      obj.base = toBase;
      elem.style.pointerEvents = "none";
      elem.style.color = "black";
      const enableElement = document.getElementById(enableElementId);
      if (enableElementId) {
        enableElement.style.pointerEvents = "auto";
        enableElement.style.color = "";
      }
    },
    convertBase(numberStr, fromBase, toBase) {
      if (fromBase === 10 && toBase === 16) {
        // eslint-disable-next-line
        return BigInt(numberStr).toString(16);
      } else if (fromBase === 16 && toBase === 10) {
        // eslint-disable-next-line
        return BigInt(`0x${numberStr}`).toString();
      } else {
        throw new Error(
          "Conversion between bases other than decimal and hexadecimal is not supported."
        );
      }
    },
  },
};
</script>

<style scoped>
.section {
  background-color: #f2f2f2;
  padding: 0px;
}
.footer {
  background-color: #333333;
  padding: 0px;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: #478ac9;
}
</style>
